.Container {
  /* height: 350vh; */
  height: 100%;
  width: 100vw;
  display: flex;
  position: relative;
}

.BackgroundWrapper {
  z-index: -1;
  /* height: 350vh; */
  height: 100%;
  width: 100vw;
  position: absolute;
  background-color: #EBEBEC;
}

.ListWrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 800px);
  margin-left: 400px;
  margin-right: 400px;
}

.ListWrapper h2 {
  font-size: 40px;
}

.DriveExclu {
  font-size: 20px;
}

.DriveExclu:hover {
  cursor: pointer;
  text-decoration: underline;
}

.Episode {
  display: flex;
  width: calc(100% - 15px);
  margin-bottom: 25px;
  height: 150px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.Episode:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.Episode img {
  width: 150px;
  height: 150px;
  border-radius: 10px 5px 5px 10px;
  margin-right: 15px;
}

.Episode img.spotify:hover {
  cursor: pointer;
}

.Episode span {
  font-size: 20px;
  font-weight: bold;
  padding-top: 5px;
  padding-right: 20px;
}

.EpisodeContent {
  display: flex;
  flex-direction: column;
}

.EpisodeContent:hover {
  cursor: pointer;
}

.Episode .ExtraText {
  font-size: 15px;
  font-weight: normal;
  font-style: italic;
}

.SpotifyMobile {
  display: none;
}

.Spotify:hover {
  cursor: pointer;
}

/*
** Responsive
*/

@media (max-width: 1400px) {
  .ListWrapper {
    width: calc(100% - 400px);
    margin-left: 200px;
    margin-right: 200px;
  }
}

@media (max-width: 1000px) {
  .ListWrapper {
    width: calc(100% - 200px);
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media (max-width: 700px) , (max-width: 500px) {
  .ListWrapper {
    width: calc(100% - 100px);
    margin-left: 50px;
    margin-right: 50px;
  }

  .ListWrapper h2 {
    font-size: 30px;
  }

  .Episode {
    height: 75px;
  }

  .Episode img {
    width: 75px;
    height: 75px;
  }

  .Episode span {
    font-size: 10px;
    padding-top: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 500px) {
  .Episode .ExtraText {
    display: none;
    margin: 0px;
    padding: 0px;
  }

  .Spotify {
    display: none;
  }

  .SpotifyMobile {
    margin-top: auto;
    display: block;
  }
}