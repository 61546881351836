.Container {
  min-height: calc(100vh - 350px);
  height: 100%;
  width: 100vw;
  display: flex;
  position: relative;
}

.BackgroundWrapper {
  z-index: -1;
  min-height: calc(100vh - 350px);
  height: 100%;
  width: 100vw;
  position: absolute;
  background-color: #EBEBEC;
}

.BodyWrapper {
  height: 100%;
  width: calc(100% - 700px);
  margin: 50px 350px 50px 350px;
}

.Listen {
  width: 100%;
  height: 100%;
}

.SectionTitle {
  margin-top: 0;
  font-size: x-large;
}

.Iframe {
  width: 100%;
  height: 100%;
}

.PlatformListen {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  margin-bottom: 30px;
}

.Platform:hover {
  cursor: pointer;
}

.Description {
  margin-top: 75px;
}

/*
** Responsive
*/

@media (max-width: 1200px) {
  .BodyWrapper {
    width: calc(100% - 500px);
    margin: 50px 250px 50px 250px;
  }
}

@media (max-width: 1000px) {
  .BodyWrapper {
    width: calc(100% - 300px);
    margin: 50px 150px 50px 150px;
  }
}

@media (max-width: 900px) {
  .BodyWrapper {
    width: calc(100% - 100px);
    margin: 50px 50px 50px 50px;
  }

  .PlatformListen {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 30px;
  }

  .Platform {
    margin-bottom: 10px;
  }
}

@media (max-width: 500px) {
}