.Container {
  height: 75vh;
  width: 100vw;
  display: flex;
  position: relative;
}

.BackgroundWrapper {
  z-index: -1;
  height: 75vh;
  width: 100vw;
  position: absolute;
  background-image: url(https://d12xoj7p9moygp.cloudfront.net/images/profile/heading-bg-wave-desktop.svg);
  background-repeat: repeat-x;
  /* background-position: center bottom; */
  background-position: center calc(75vh - 295px);
  background-size: 2124px 300px;
  background-color: #1255AC;
}

.HeroWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.LeftPart {
  display: flex;
  justify-content: flex-end;
  width: calc(40% - 300px);
  height: calc(100% - 75px);
  margin-top: 75px;
  margin-left: 200px;
  margin-right: 100px;
}

.LeftPart img {
  width: 50vh;
  height: 50vh;
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.RightPart {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  width: calc(60% - 200px);
  height: calc(100% - 75px);
  margin-right: 200px;
  margin-top: 75px;
  color: white;
}

.DescriptionText {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
}

.DescriptionText h1 {
  font-size: 10vh;
  margin-top: 0;
  margin-bottom: 0;
}

.DescriptionText p {
  font-size: 2vh;
  margin-top: 0;
  margin-bottom: 50px;
}

.CallToAction {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.CallToAction span {
  font-size: 2vh;
  font-weight: bold;
}

.InputWrapper {
  display: flex;
  flex-direction: row;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  margin-top: 15px;
}

.Input {
  display: flex;
  width: 100%;
  font-size: 1vh;
  font-weight: bold;
  padding: 30px;
  border-radius: 20px;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  margin-right: 15px;
}

.Button {
  display: flex;
  color: #707070;
  background-color: #FFDE59;
  font-size: 1vh;
  font-weight: bold;
  padding: 30px;
  border-radius: 20px;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  cursor: pointer;
}

.Button:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/*
** Responsive
*/

@media (max-width: 2600px) {
  .DescriptionText p {
    font-size: 3vh;
    margin-bottom: 25px;
  }

  .CallToAction span {
    font-size: 3vh;
  }

  .Input {
    padding: 15px;
    font-size: 2vh;
  }

  .Button {
    padding: 15px;
    font-size: 2vh;
  }
}

@media (max-width: 1400px) {
  .LeftPart {
    width: calc(40% - 100px);
    margin-left: 100px;
  }

  .LeftPart img {
    width: 40vh;
    height: 40vh;
  }

  .RightPart {
    width: calc(60% - 100px);
    margin-right: 100px;
  }

  .DescriptionText h1 {
    font-size: 7vh;
  }

  .DescriptionText p {
    font-size: 2vh;
  }

  .CallToAction span {
    font-size: 2vh;
  }

  .Input {
    font-size: 1vh;
  }

  .Button {
    font-size: 1vh;
  }
}

@media (max-width: 1000px) , (max-width: 1100px) and (min-height: 1000px) {
  .Container {
    height: 100vh;
  }

  .BackgroundWrapper {
    height: 100vh;
    background-position: center calc(100vh - 295px);
  }

  .HeroWrapper {
    flex-direction: column;
  }

  .LeftPart {
    width: 100%;
    height: 30%;
    margin-left: 0px;
    justify-content: center;
  }

  .RightPart {
    width: calc(100% - 200px);
    height: 70%;
    margin-left: 100px;
    margin-right: 100px;
    justify-content: center;
    margin-top: 0px;
  }

  .DescriptionText h1 {
    font-size: 6vh;
  }

  .CallToAction {
    width: 100%;
  }
}

@media (max-width: 700px) , (max-width: 500px) {
  .LeftPart {
    width: 100%;
    height: calc(50% - 50px);
    margin-top: 50px;
  }

  .RightPart {
    width: calc(100% - 60px);
    height: calc(60% - 30px);
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
  }

  .LeftPart img {
    width: 75vw;
    height: 75vw;
  }

  .DescriptionText h1 {
    font-size: 4vh;
  }
}
