.Container {
  height: 450px;
  width: 100vw;
  display: flex;
  position: relative;
}

.BackgroundWrapper {
  z-index: -1;
  height: 450px;
  width: 100vw;
  position: absolute;
  background-image: url(https://d12xoj7p9moygp.cloudfront.net/images/profile/heading-bg-wave-desktop.svg);
  background-repeat: repeat-x;
  background-position: center calc(450px - 125px);
  /* background-size: 2124px 300px; */
  background-size: 750px 150px;
  background-color: #1255AC;
}

.HeaderWrapper {
  width: calc(100% - 500px);
  margin: 50px 250px 50px 250px;
}

.OverTitle {
  color: white;
}

.Title {
  color: white;
  font-size: xxx-large;
  margin-bottom: 0;
}

.TypeTag {
  color: white;
  font-style: italic;
  margin-top: 0;
}

.SubTitle {
  color: white;
  font-weight: normal;
}

.SubTitleLink {
  text-decoration: none;
  text-decoration: underline;
  color: white;
  font-weight: normal;
}

/*
** Responsive
*/

@media (max-width: 1200px) {
  .HeaderWrapper {
    width: calc(100% - 300px);
    margin: 50px 150px 50px 150px;
  }
}

@media (max-width: 1000px) {
  .HeaderWrapper {
    width: calc(100% - 100px);
    margin: 50px 50px 50px 50px;
  }
}

@media (max-width: 900px) {
  .HeaderWrapper {
    width: calc(100% - 50px);
    margin: 50px 25px 25px 25px;
  }

  .Title {
    font-size: xx-large;
    margin-top: 25px;
    margin-bottom: 0;
  }
}

@media (max-width: 500px) {
}