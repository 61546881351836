.Container {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  padding: 25px;
}

.LeftPart {
  display: flex;
  height: 100%;
  width: 30%;
}

.LeftPart img {
  width: 200px;
  height: 100%;
  /* border-radius: 10px 5px 5px 10px; */
  margin-right: 15px;
}

.RightPart {
  display: flex;
  height: 100%;
  width: calc(70% - 25px);
  max-width: 450px;
  padding-left: 25px;
  justify-content: center;
}

.FormWrapper {
  width: 100%;
  height: 100%;
}

.CallToAction {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.CallToAction span {
  font-size: 32px;
  font-weight: bold;
}

.InputWrapper {
  display: flex;
  flex-direction: column;
  width: calc(100%);
  height: calc(100% - 30px);
  margin-top: 45px;
}

.Input {
  display: flex;
  max-width: 250px;
  font-weight: bold;
  padding: 10px 15px 10px 15px;
  border-radius: 20px;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  margin-right: 15px;
}

.Button {
  display: flex;
  margin-right: auto;
  color: #707070;
  background-color: #FFDE59;
  font-weight: bold;
  margin-top: 15px;
  padding: 10px 15px 10px 15px;
  border-radius: 20px;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  cursor: pointer;
}

.Button:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}


/*
** Responsive
*/

@media (max-width: 2600px) {
}

@media (max-width: 1400px) {
}

@media (max-width: 1000px) , (max-width: 1100px) and (min-height: 1000px) {
}

@media (max-width: 700px) , (max-width: 500px) {
  .LeftPart{
    display: none;
  }

  .RightPart {
    width: 100%;
    padding-left: 0px;
    max-width: 200px;
  }

  .CallToAction span {
    font-size: 12px;
  }

  .CallToAction p {
    font-size: 9px;
  }

  .InputWrapper {
    margin-top: 15px;
  }

  .Input, .Button {
    font-size: 9px;
  }
}
